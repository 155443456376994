<template>
  <div class="mb-60 baojia-box liucheng-top rounded-lg">
    <div class="text-left  col-F63605">
          <h3 class="mb-3">{{ $t("账号设置") }}</h3>
        </div>
     <v-card class="shadow-sm pt-2 pb-2 d-flex align-center px-2 mb-4">
      <v-tabs>
        <v-tab @click="tabs = 1">
          <v-icon class="mr-2">mdi-account-cog-outline</v-icon>
          {{ $t("基本设置") }}
        </v-tab>
        <v-tab @click="tabs = 2">
          <v-icon class="mr-2">mdi-lock-reset</v-icon>
          {{ $t("修改密码") }}
        </v-tab>
      </v-tabs>
      <v-spacer />
    </v-card>
    <v-card class="pa-4 d-flex" flat>
      <div class="box" v-if="tabs==1">
        
        <div class="d-flex align-center mb-4">
          <span class="text-sharered">*</span>
          <span class="ml-2 mr-8 font-600 width-100">{{ $t("用户名") }}：</span>
            <div class="width-300">
              <v-text-field
                placeholder=""
                disabled
                outlined
                dense
                class=""
                v-model="params.name"
              ></v-text-field>
            
            </div>
             <v-btn
             @click="nicknamedialog=true"
                color="primary"
                class="ml-4"
                small
                depressed
              >{{$t('修改')}}</v-btn>
        </div>
        <div class="d-flex align-center mb-4">
          <span class="text-sharered">*</span>
          <span class="ml-2 mr-8 font-600 width-100">{{ $t("手机号") }}：</span>
          <div class="width-300">
            <v-text-field
              placeholder=""
              :rules="Rules"
              outlined
              disabled
              dense
              class=""
              v-model="params.phone"
            ></v-text-field>
          </div>
          <v-btn
              @click="changeUserinfoFn('phone')"
                color="primary"
                class="ml-4"
                small
                depressed
              >{{$t('换绑')}}</v-btn>
        </div>
        <!-- <div class="d-flex">
          <span class="text-sharered">*</span>
          <p class="text-14 mb-1 ml-2 mr-8 font-600 width-100">
            {{ $t("验证码：") }}
          </p>
          <div class="d-flex">
            <v-text-field
              :rules="Rules"
              outlined
              dense
              :placeholder="$t('请输入验证码')"
              v-model="params.yzcode"
              class="mr-10 ml-2"
            ></v-text-field>
            <v-btn
              color="primary"
              @click="sendcode()"
              v-if="!captchaOptions.status"
              >{{ $t("获取验证码") }}</v-btn
            >
            <v-btn v-else>{{ captchaOptions.seconds }}s</v-btn>
          </div>
          <span class="grey--text ml-10">{{ $t("请输入验证码") }}</span>
        </div> -->

        <div class="d-flex align-center mb-4">
          <span class="text-sharered">*</span>
          <span class="ml-2 mr-8 font-600 width-100">{{ $t("邮箱") }}：</span>
          <div class="width-300">
            <v-text-field
              placeholder=""
              :rules="Rules"
              outlined
              disabled
              dense
              class=""
              v-model="params.email"
            ></v-text-field>
          </div>
          <v-btn
              @click="changeUserinfoFn('email')"
                color="primary"
                class="ml-4"
                small
                depressed
              >{{$t('换绑')}}</v-btn>
        </div>
        <div class="d-flex">
          <span class="text-sharered">*</span>
          <span class="ml-2 mr-8 font-600 width-100">{{
            $t("链接账户：")
          }}</span>
          <div>
            <v-img
              class="cursor"
              width="60"
              src="@/assets/images/person/taobaolinkadd.png"
            ></v-img>
          </div>
        </div>
        <div class="mt-8">
          <div class="d-flex">
            <span class="text-sharered">*</span>
            <span class="ml-2 mr-8 font-600 width-100">{{
              $t("支付宝账户：")
            }}</span>
            <div>
              <div class="mb-1" v-for="(item, index) in user.alipay
" :key="index">
                <span class="mr-2">{{item.name}}-{{item.bank_name}}-{{item.code}}</span>
                <span class="cursor text-sharered" @click="delAcount(item)">{{ $t("删除") }}</span>
              </div>
              <p class="cursor text-sharered" v-if="user.alipay && user.alipay.length==0">
                <router-link to="/user/Withdrawa">{{ $t("去添加") }}</router-link>
                
              </p>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <div class="d-flex">
            <span class="text-sharered">*</span>
            <span class="ml-2 mr-8 font-600 width-100">{{
              $t("银行卡账户：")
            }}</span>
            <div class="mb-4">
              <div class="mb-1" v-for="(item, index) in user.bank
" :key="index">
                <span class="mr-2">{{item.name}}-{{item.bank_name}}-{{item.code}}</span>
                <span class="cursor text-sharered" @click="delAcount(item)">{{ $t("删除") }}</span>
              </div>
              <p class="cursor text-sharered" v-if="user.bank && user.bank.length==0">
                <router-link to="/user/Withdrawa">{{ $t("去添加") }}</router-link>
              </p>
            </div>
          </div>
        </div>
        
        <div class="d-flex">
          <span class="ml-2 mr-10 font-600 width-100"></span>
          <v-btn color="primary"  class="text-center" depressed>{{
            $t("保存信息")
          }}</v-btn>
        </div>
      </div>
      <div class="box" v-if="tabs==2">
        <div class="d-flex">
          <span class="text-sharered">*</span>
          <span class="ml-2 mr-8 font-600 width-100">{{
            $t("登录密码：")
          }}</span>
          <div class="width-300">
            <v-text-field
              placeholder=""
              :rules="Rules"
              outlined
              dense
              type="password"
              class="mb-4"
              v-model="params.password"
            ></v-text-field>
          </div>
          <span class="grey--text ml-10">{{
            $t("请输入8-16位数字或字母")
          }}</span>
        </div>
        <div class="d-flex">
          <span class="text-sharered">*</span>
          <span class="ml-2 mr-8 font-600 width-100">{{
            $t("重新确认密码：")
          }}</span>
          <div class="width-300">
            <v-text-field
              placeholder=""
              :rules="Rules"
              outlined
              dense
              type="password"
              class="mb-4"
              v-model="params.passwords"
            ></v-text-field>
          </div>
          <span class="grey--text ml-10">{{
            $t("请输入重复输入登录密码")
          }}</span>
        </div>
        <div class="d-flex">
          <span class="ml-2 mr-10 font-600 width-100"></span>
          <v-btn color="primary" class="text-center" depressed @click="saveInfo">{{
            $t("保存信息")
          }}</v-btn>
        </div>
      </div>
    </v-card>
    <!-- 修改 用户名-->
    <v-dialog v-model="nicknamedialog" width="450">
      <v-card class="pa-4">
        <p class="text-body-1 font-weight-bold">{{ $t("用户名修改") }}</p>
        <p class="text-14 mb-2">{{ $t("原用户名") }}</p>
        <v-text-field
          :placeholder="$t('请输入用户名')"
          :rules="Rules"
          outlined
          disabled
          dense
          class="mb-4"
          v-model="params.name"
        ></v-text-field>
        <p class="text-14 mb-2">{{ $t("新用户名") }}</p>
        <v-text-field
          :placeholder="$t('请输入新用户名')"
          :rules="Rules"
          outlined
          dense
          color="#F2F2F2"
          class="mb-4"
          v-model="newName"
        ></v-text-field>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            width="100"
            outlined
            class="text-center"
            color="primary"
            @click="nicknamedialog = false"
            >{{ $t("取消") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-center white--text"
            color="primary"
            @click="submitNickname('zfb')"
            >{{ $t("确认") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 修改个人信息弹窗 -->
    <v-dialog v-model="userdialog" width="450">
      <v-card class="pa-4">
        <!-- 手机号 -->
        <div v-if="changeusertype=='phone'">
          <p class="text-body-1 font-weight-bold">{{ editTitle }}</p>
          <p class="text-14 mb-2">{{ $t("原手机号") }}</p>
          <v-text-field
            :placeholder="$t('请输入手机号')"
            :rules="Rules"
            outlined
            disabled
            
            dense
            class="mb-4"
            v-model="params.phone"
          ></v-text-field>
          
          <p class="text-14 mb-2">{{ $t("新手机号") }}</p>
          <v-text-field
            :placeholder="$t('请输新手机号')"
            :rules="Rules"
            outlined
            dense
            color="#F2F2F2"
            class="mb-4"
            v-model="newPhone"
          ></v-text-field>
          <p class="text-14 mb-2">
            {{ $t("验证码：") }}
          </p>
          <div class="d-flex mb-4">
            <v-text-field
              :rules="Rules"
              outlined
              dense
              
              :placeholder="$t('请输入验证码')"
              v-model="params.yzcode"
              class="mr-10 "
            ></v-text-field>
            <v-btn
              color="primary"
              @click="sendcode()"
              v-if="!captchaOptions.status"
              >{{ $t("获取验证码") }}</v-btn
            >
            <v-btn v-else>{{ captchaOptions.seconds }}s</v-btn>
          </div>
        </div>
        <div v-if="changeusertype=='email'">
          <p class="text-body-1 font-weight-bold">{{ editTitle }}</p>
          <p class="text-14 mb-2">{{ $t("原邮箱") }}</p>
          <v-text-field
            :placeholder="$t('请输入邮箱')"
            :rules="Rules"
            outlined
            dense
            disabled
            class="mb-4"
            v-model="params.email"
          ></v-text-field>
          <p class="text-14 mb-2">{{ $t("新邮箱") }}</p>
          <v-text-field
            :placeholder="$t('请输入邮箱')"
            :rules="Rules"
            outlined
            dense
            color="#F2F2F2"
            class="mb-4"
            v-model="newEmail"
          ></v-text-field>
          <p class="text-14 mb-2">
            {{ $t("验证码：") }}
          </p>
          <div class="d-flex mb-4">
            <v-text-field
              :rules="Rules"
              outlined
              dense
              
              :placeholder="$t('请输入验证码')"
              v-model="params.yzcode"
              class="mr-10 "
            ></v-text-field>
            <v-btn
              color="primary"
              @click="sendcode()"
              v-if="!captchaOptions.status"
              >{{ $t("获取验证码") }}</v-btn
            >
            <v-btn v-else>{{ captchaOptions.seconds }}s</v-btn>
          </div>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            width="100"
            outlined
            class="text-center"
            color="primary"
            @click="userdialog = false"
            >{{ $t("取消") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-center white--text"
            color="primary"
            @click="submitUserinfo()"
            >{{ $t("确认") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- 添加支付宝账号弹窗 -->
    <v-dialog v-model="zfbdialog" width="450">
      <v-card class="pa-4">
        <p class="text-body-1 font-weight-bold">{{ $t("添加支付宝账户") }}</p>
        <p class="text-14 mb-2">{{ $t("姓名") }}</p>
        <v-text-field
          :placeholder="$t('请输入个人信息')"
          :rules="Rules"
          outlined
          dense
          class="mb-4"
          v-model="zfdata.name"
        ></v-text-field>
        <p class="text-14 mb-2">{{ $t("支付宝账号") }}</p>
        <v-text-field
          :placeholder="$t('请输入账号')"
          :rules="Rules"
          outlined
          dense
          color="#F2F2F2"
          class="mb-4"
          v-model="zfdata.code"
        ></v-text-field>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            width="100"
            outlined
            class="text-center"
            color="primary"
            @click="zfbdialog = false"
            >{{ $t("取消") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-center white--text"
            color="primary"
            @click="submitCode('zfb')"
            >{{ $t("确认") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 添加银行卡账号弹窗 -->
    <v-dialog v-model="bankdialog" width="450">
      <v-card class="pa-4">
        <p class="text-body-1 font-weight-bold">{{ $t("添加银行卡账户") }}</p>
        <p class="text-14 mb-2">{{ $t("姓名") }}</p>
        <v-text-field
          :placeholder="$t('请输入个人信息')"
          :rules="Rules"
          outlined
          dense
          class="mb-4"
          v-model="bankdata.name"
        ></v-text-field>
        <p class="text-14 mb-2">{{ $t("银行卡号") }}</p>
        <v-text-field
          :placeholder="$t('请输入账号')"
          :rules="Rules"
          outlined
          dense
          color="#F2F2F2"
          class="mb-4"
          v-model="bankdata.bankcode"
        ></v-text-field>
        <p class="text-14 mb-2">{{ $t("银行") }}</p>
        <v-text-field
          :placeholder="$t('请输入银行')"
          :rules="Rules"
          outlined
          dense
          color="#F2F2F2"
          class="mb-4"
          v-model="bankdata.bank"
        ></v-text-field>
        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            width="100"
            outlined
            class="text-center"
            color="primary"
            @click="bankdialog = false"
            >{{ $t("取消") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-center white--text"
            color="primary"
            @click="submitCode('bank')"
            >{{ $t("确认") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        name: "",
        email:"",
        phone: "",
        yzcode: "",
        password: "",
        passwords: "",
      },
      tabs:1,
      zfdata: {
        name: "",
        code: "",
      },
      bankdata: {
        name: "",
        bankcode: "",
        bank: "",
      },
      user:{},
      zfbdialog: false,
      bankdialog: false,
      Rules: [(v) => !!v || this.$t("必填项")],
      captchaOptions: {
        status: false,
        seconds: 60,
        timer: null,
      },
      userdialog:false, //修改用户信息弹窗
      nicknamedialog:false, //修改用户名弹窗
      changeusertype:'phone',
      newPhone:"",
      newEmail:"",
      newName:"",
      editTitle:this.$t('换绑手机号')
    };
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    // 获取个人信息
    getUserInfo(){
      this.$api.user.getUserInfo().then((res)=>{
        if(res.status=='success'){
          this.user=res.data
          this.params.email=this.user.email
          this.params.name=this.user.nick_name
          this.params.phone=this.user.phone
        }
      })
    },
    // 点击换绑信息
    changeUserinfoFn(type){
      this.changeusertype=type
      this.editTitle= type=='phone' ? this.$t('换绑手机号') : this.$t('换绑邮箱');
      this.userdialog=true

    },
    // 修改用户名
    submitNickname(){
      if(!this.newName){
        if(this.newPhone==''){
          this.$Toast({ content: this.$t('请填写用户名'), type: "error" });
          return 
        }
      }
       this.$api.user.editUsername({nickname:this.newName}).then((res)=>{
          if(res.status=='success'){
            this.$Toast({ content: res.msg, type: "success" });
            this.$api.user.getUserInfo().then((res)=>{
              if(res.status=='success'){
                this.user=res.data
                this.params.email=this.user.email
                this.params.name=this.user.nick_name
                this.params.phone=this.user.phone
                this.$store.commit("setUSERINFO", res.data);
              }
            })
          }else {
            this.$Toast({ content: res.msg, type: "error" });
          }
          this.nicknamedialog=false
        })
    },
    // 确认修改手机号或者邮箱
    submitUserinfo(){
      if(this.changeusertype=='phone'){
        if(this.newPhone==''){
          this.$Toast({ content: this.$t('请填写手机号'), type: "error" });
          return 
        }
        this.$api.user.editPhone({phone:this.newPhone,code:this.params.yzcode}).then((res)=>{
          if(res.status=='success'){
            this.$Toast({ content: res.msg, type: "success" });
            this.getUserInfo()
          }else {
            this.$Toast({ content: res.msg, type: "error" });
          }
          this.userdialog=false
        })
      }else if(this.changeusertype=='email'){
        if(this.newEmail==''){
          this.$Toast({ content: this.$t('请填写邮箱'), type: "error" });
          return 
        }
        this.$api.user.editEmail({email:this.newEmail,code:this.params.yzcode}).then((res)=>{
          if(res.status=='success'){
            this.$Toast({ content: res.msg, type: "success" });
            this.getUserInfo()
          }else {
            this.$Toast({ content: res.msg, type: "error" });
          }
          this.userdialog=false
        })
      }
    },
    // 删除账号信息
    delAcount(item){
      this.$api.order.deleteAcount({id:item.id}).then((res)=>{
        if(res.status=='success'){
          this.$Toast({ content: res.msg, type: "success" });
          this.getUserInfo()
        }else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      })
    },
    // 保存密码信息

    saveInfo(){
      if(this.params.password != this.params.passwords){
        this.$Toast({ content:this.$t('两次输入密码不一致'), type: "error" });
        return 
      }
      this.$api.user.editPassword({password:this.params.password}).then((res)=>{
          if(res.status=='success'){
            this.$Toast({ content: this.$t('修改成功,请重新登录!'), type: "success" });
            setTimeout(()=>{
              this.logout()
            },2000)
          }else {
            this.$Toast({ content: res.msg, type: "error" });
          }
        })
    },
    logout() {
      this.$store.commit("setUSERINFO", null);
      this.$store.commit("setTOKEN", null);
      this.$store.commit("setcartnum", "0");
      this.$router.push("/sign-in");
    },
    submitCode() {},
     sendcode() {
      let account=""
      if(this.changeusertype=='phone'){
         account=this.newPhone
      } else if(this.changeusertype=='email'){
        account=this.newEmail
      }
      if (account) {
        this.captchaOptions.status = true;
        this.codesub();
        this.$api.user
          .getVerifiCode({ username: account })
          .then((res) => {
            if (res.status=='error') {
              this.$Toast({ content: res.msg, type: "error" });
            }
          });
      }else {
        this.$Toast({ content:this.$t('请填写必要信息'), type: "error" });
      }
    },
    codesub() {
      let seconds = 60,
        that = this;
      this.captchaOptions.timer = setInterval(() => {
        seconds--;
        that.captchaOptions.seconds = seconds;
        if (seconds < 1) {
          that.captchaOptions.status = false;
          that.captchaOptions.seconds = 60;
          clearInterval(that.captchaOptions.timer);
        }
      }, 1000);
    },
  },
};
</script>
<style  scoped>
.text-sharered {
  color: #f63605;
}
.width-100 {
  width: 100px;
}
.width-300{
  width: 300px;
}
.box {
  @media (max-width: 500px) {
    width: 100%;
  }
}
.v-input__control {
   
}
/deep/ .v-text-field__details{
    display: none;
  }
</style>